<template>
  <div>
    <div class="mb-16" style="display: flex; align-items: center;">
      <h1 class="heading color-primary heading--bigger">
        mój monitoring
      </h1>
      <legislation-switch
      v-if="clientWidth <= 767"
      tag="div"
      />
    </div>

    <item-nav
      filter-other
    />

    <router-view />
  </div>
</template>

<script>
import ItemNav from '../../components/ItemNav';
import LegislationSwitch from '../../components/LegislationSwitch.vue';
import mixinLegislationSwitch from '../../components/mixinLegislationSwitch';

export default {
  components: {
    ItemNav,
    LegislationSwitch
  },
  mixins: [mixinLegislationSwitch]
};
</script>
